require('./bootstrap');
import anime from 'animejs/lib/anime.es.js';
var Turbolinks = require("turbolinks")
Turbolinks.start()


document.addEventListener("turbolinks:load", function () {
    var shapes = [
        {
            path: 'M 262.9,252.2 C 210.1,338.2 212.6,487.6 288.8,553.9 372.2,626.5 511.2,517.8 620.3,536.3 750.6,558.4 860.3,723 987.3,686.5 1089,657.3 1168,534.7 1173,429.2 1178,313.7 1096,189.1 995.1,130.7 852.1,47.07 658.8,78.95 498.1,119.2 410.7,141.1 322.6,154.8 262.9,252.2 Z',
            pathAlt: 'M 262.9,252.2 C 210.1,338.2 273.3,400.5 298.5,520 323.7,639.6 511.2,537.2 620.3,555.7 750.6,577.8 872.2,707.4 987.3,686.5 1102,665.6 1218,547.8 1173,429.2 1128,310.6 1096,189.1 995.1,130.7 852.1,47.07 658.8,78.95 498.1,119.2 410.7,141.1 322.6,154.8 262.9,252.2 Z',
            scaleX: 1.4,
            scaleY: 1.4,
            rotate: 0,
            tx: 0,
            ty: 0,
            fill: {
                color: '#181818',
                duration: 1,
                easing: 'linear'
            },
            animation: {
                path: {
                    duration: 3000,
                    easing: 'easeOutElastic',
                    elasticity: 600
                },
                svg: {
                    duration: 2000,
                    easing: 'easeOutElastic'
                }
            }
        }
    ];




    if (document.getElementById('animation')) {
        var DOM = {};
        DOM.svg = document.querySelector('.morph');
        DOM.shapeEl = DOM.svg.querySelector('path');

        var initShapeEl = function () {
            anime.remove(DOM.svg);
            anime({
                targets: DOM.svg,
                duration: 1,
                easing: 'linear',
                scaleX: shapes[0].scaleX,
                scaleY: shapes[0].scaleY,
                translateX: shapes[0].tx + 'px',
                translateY: shapes[0].ty + 'px',
                rotate: shapes[0].rotate + 'deg'
            });

            initShapeLoop();
        };
    }

    var initShapeLoop = function (pos) {
        pos = pos || 0;
        anime.remove(DOM.shapeEl);
        anime({
            targets: DOM.shapeEl,
            easing: 'linear',
            d: [{ value: shapes[pos].pathAlt, duration: 3500 }, { value: shapes[pos].path, duration: 3500 }],
            loop: true,
            fill: {
                value: shapes[pos].fill.color,
                duration: shapes[pos].fill.duration,
                easing: shapes[pos].fill.easing
            },
            direction: 'alternate'
        });
    };

    if(document.getElementById('animation')) {

        setTimeout(() => {
            document.getElementsByClassName('right-hover')[0].classList.add('right-hover-activate');
            document.getElementsByClassName('left-hover')[0].classList.add('left-hover-activate');
            document.getElementById('stage').classList.remove('opacity-0');
        }, 1000);


        initShapeEl();
    }

});
